import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../validators/custom-validators';
import {MatSnackBar} from '@angular/material';
import { BackendService } from '../../service/backend.service';
import { AlertService } from '../../core/alert.service';

@Component({
  selector: 'app-cambiar-contrasena',
  templateUrl: './cambiar-contrasena.component.html',
  styleUrls: ['./cambiar-contrasena.component.css']
})
export class CambiarContrasenaComponent implements OnInit {
  hide = true;
  hide2 = true;
  hide3 = true;
  public form: FormGroup;
  isContentLoading = false;
  perfil : any;
  nombre : any;
  usuario = {
    contrasena_anterior:'',
    contrasena_nueva:'',
    usuario_id:0
  }
  constructor(private fb : FormBuilder, public snackBar : MatSnackBar, public back : BackendService, private alert:AlertService) 
  {}

 
  ngOnInit() {
    this.form = this.fb.group({
      contrasena_anterior: [null, Validators.compose([Validators.required])],
      nueva_contrasena: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      confirma_contrasena: [null, Validators.compose([Validators.required])]
    },{
      validator: CustomValidators.passwordMatchValidator
    });
    this.nombre = JSON.parse(localStorage.getItem('usuario')).nombre
    this.perfil = JSON.parse(localStorage.getItem('usuario')).perfil
  }
  

  cambiarPassword(){
    this.usuario['usuario_id'] = JSON.parse(localStorage.getItem('usuario')).id
    this.back.modificarContrasena(this.usuario).subscribe( 
      result =>{
        this.alert.showNotification('top','right','La contraseña fue actualizada exitosamente',1)
        this.form.reset()        
    },
      (err)=>{
        this.back.obtener_mensajes_error(err)
    })
  }

}
