import { Injectable } from '@angular/core';
import { filesStructs } from '../structs/activos.struct';
import { UploadComponent } from '../uploader/upload/upload.component';
import { DialogComponent } from '../confirm/dialog/dialog.component';
import { ModalComponent } from '../utils/modalImg/dialog/dialog.component'
import { MatDialog } from '@angular/material';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
@Injectable()
export class Generics {
  constructor(public dialog: MatDialog, private storage: AngularFireStorage) {

  }

  dictionary(arrValueData) {
    let dictionary: any = {}
    arrValueData.map((e) => {
      return dictionary[e.nombre] = e.valor
    });

    return dictionary
  }

  getIcon(tipo) {
    let icon: string
    Object.keys(filesStructs).map(res => {
      if (res == tipo)
        icon = filesStructs[res].icon
    })
    return icon
  }

  getArrayValue(arrValues) {
    let newArr = []
    arrValues.map(res => {
      if (res.value.trim() !== "")
        newArr.push(res.value.trim())
    })
    return newArr
  }

  deleteOnArray(Arr, id) {
    let uid = Arr.map(res => { return res.id }).indexOf(id)
    Arr.splice(uid, 1)
    return Arr
  }

  dynamicInput(arrItems) {
    arrItems.push({
      label: `Código ${arrItems[arrItems.length - 1] ? arrItems[arrItems.length - 1].id + 1 : 1}`,
      value: '',
      id: arrItems[arrItems.length - 1] ? arrItems[arrItems.length - 1].id + 1 : 1
    })
    return arrItems
  }

  getTypeView(type) {
    let titulos = {
      entrada: {
        value: 'Alta de Activo'
      },
      traspaso: {
        value: 'Traspaso de Activo'
      },
      codigos: {
        value: 'Actualización de Código'
      },
      atributos: {
        value: 'Actualización de Atributo'
      },
      media_agregar: {
        value: 'Alta de Media'
      },
      media_eliminar: {
        value: 'Eliminación de Media'
      },
      documento_agregar: {
        value: 'Alta de Documento'
      },
      documento_eliminar: {
        value: 'Eliminación de Documento'
      },
      actualizar_ubicacion: {
        value: 'Actualización de Ubicación'
      },
      salida: {
        value: 'Salida de activo'
      }
    }
    return titulos[type].value
  }

  permissions(permits) {
    let status: boolean
    permits.map(permit => {
      if (JSON.parse(localStorage.getItem('usuario')).credenciales.includes(permit))
        status = true
    })
    return status
  }

  deleteFbaseDocs(url){
    return this.storage.storage.refFromURL(url).delete()
  }

}