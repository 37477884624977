// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBBg_mfmHlATAvrlCPz2QBwShIG21pA-gM",
    authDomain: "sistemaampi.firebaseapp.com",
    databaseURL: "https://sistemaampi.firebaseio.com",
    projectId: "sistemaampi",
    storageBucket: "sistemaampi.appspot.com",
    messagingSenderId: "24545016652",
    appId: "1:24545016652:web:46b50ea86d86c6180d2de9",
    measurementId: "G-0DNE3QJ7MF"
  }
}

export const enviroment = {
  // url:'https://ampi.stacktree.mx/'
  url:'https://yew.stacktree.mx/toktok:ampi/'
} 
