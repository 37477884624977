import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'permissions'
})
export class PermissionPipe implements PipeTransform {
  resum = []
  transform(value, args: string[]): any {
    value.map(res => {
      this.checkMain(res)
    })
    return this.resum
  }

  checkMain(res) {
    res.permission.map(Permission => {
      if (JSON.parse(localStorage.getItem('usuario')).credenciales.includes(Permission) && !this.resum.includes(res))
        this.resum.push(res)
    })

  }
}