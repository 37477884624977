import { Injectable } from '@angular/core';

declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class AlertService  {

  constructor() {

   }

  showNotification(from, align, msg, type){
    let kind = ['','info','success','warning','danger'];
    var color = Math.floor((Math.random() * 4) + 1);
    let icon={
      "success":{
        name:'ti-thumb-up'
      },
      "fail":{
        name:'ti-alert'
      }
    }
    let iconShow

    if(type == 1)
      iconShow = icon.success.name
    else
      iconShow = icon.fail.name

  $.notify({
      icon: iconShow,
      message: msg
    },{
        type: kind[type],
        timer: 4000,
        placement: {
            from: from,
            align: align
        }
    });    
}

toLookup(){
 let status = true
 let img = 10
 let size = 20
 if(img > 10)
  status = false
 else if(size > 19)
  status = false

  return status
}
}
