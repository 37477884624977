import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

export class CustomValidators {
  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('nueva_contrasena').value; // get password from our password form control
    const confirmPassword: string = control.get('confirma_contrasena').value; // get password from our confirmPassword form control

    if (password !== confirmPassword) {
      control.get('confirma_contrasena').setErrors({ NoPassswordMatch: true });
    }
  }
}