import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild, HostListener, Directive, AfterViewInit } from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { BackendService } from '../../service/backend.service';
import * as moment from 'moment'
import { StorageService } from '../../utils/languajeService'
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: [],
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  dir = 'ltr';
  green: boolean;
  blue: boolean;
  dark: boolean;
  minisidebar: boolean;
  boxed: boolean;
  danger: boolean;
  showHide: boolean;
  sidebarOpened: boolean = true;
  loaded: boolean
  rol: Array<any> = JSON.parse(localStorage.getItem('usuario'))['roles'][0]
  notis = []
  public isVisibleSpinner: boolean = false;

  // public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  constructor(private storageService: StorageService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public menuItems: MenuItems, private rest: BackendService) {
    console.log('====================================');
    console.log(this.rol);
    console.log('====================================');
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.getNotis()
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.storageService.watchStorage().subscribe((data:string) => {
      console.log('entroasaS');
      
            })
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }


  ngAfterViewInit() {
    // This is for the topbar search
    (<any>$('.srh-btn, .cl-srh-btn')).on('click', function () {
      (<any>$('.app-search')).toggle(200);
    });
    // This is for the megamenu
  }

  getNotis() {
    let notis = []
    this.rest.notis().subscribe(r => {
      r['data'].map(r => {
        const { id, data: { evento, message }, created_at } = r
        notis.push({ id, evento,message, created_at: moment(created_at).format('DD-MM-YYYY: h:m:s') })
      })
      this.notis = notis
      console.log(this.notis)
    })
  }

  logOut() {
    this.rest.cerrarSession();
  }

  validateRol = (rols: Array<any>) => rols.includes(this.rol)




}
