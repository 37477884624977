export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBBg_mfmHlATAvrlCPz2QBwShIG21pA-gM",
    authDomain: "sistemaampi.firebaseapp.com",
    databaseURL: "https://sistemaampi.firebaseio.com",
    projectId: "sistemaampi",
    storageBucket: "sistemaampi.appspot.com",
    messagingSenderId: "24545016652",
    appId: "1:24545016652:web:46b50ea86d86c6180d2de9",
    measurementId: "G-0DNE3QJ7MF"
  }
}

export const enviromentProd = {
  // url:'https://ampi.stacktree.mx/'
  url:'https://yew.stacktree.mx/toktok:ampi/'
} 
