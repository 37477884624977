import { ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild, HostListener, Directive, AfterViewInit, Input } from '@angular/core';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { BackendService } from '../../../service/backend.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  @Input() state: any;
  // public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  menuItems: any;
  items: InterfaceData[] = [];
  active: boolean = true
  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public menu: MenuItems,
    private rest: BackendService) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.menuItems = menu.getMenuitem();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logOut() {
    // this.isVisible = true;
    this.rest.cerrarSession();
  }
}

export interface InterfaceData {
  state: any;
  name: any;
  type: any;
  icon: any;
  perfiles: any;
}