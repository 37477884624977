//producto
export const Product = {
  id: '',
  unidad: '',
  producto: '',
  descripcion: '',
  atributos: [],
  unidadId:''
}
//Archivos
export const filesStructs = {
  excel: {
    icon: './assets/images/files/icon-xls.png'
  },
  powerpoint: {
    icon: './assets/images/files/icon-file.png'
  },
  word: {
    icon: './assets/images/files/icon-doc.png'
  },
  pdf: {
    icon: './assets/images/files/icon-pdf.png'
  }
}

export const displayedColumns =
  [
    'nombre',
    'valor',
    'acciones'
  ]
/*Documentos */
export const documentsColumns =
  [
    'archivo',
    'nombre',
    'fecha',
    'proviene',
    'acciones'
  ]

//Bitacora
export const bitacoraColumns =
  [
    'nombre',
    'fecha',
    'acciones'
  ]

//Almacenes
export const almacenesColumns =
  [
    'nombre',
    'pertenece'
  ]

export const bitacoraDetalle = {
  entrada: {
    fechahora: '',
    evento: '',
    detalles: {
      producto: '',
      codigos: '',
      unidad_almacenaje: '',
      nombre_unidad_almacenaje: '',
      latitud: '',
      longitud: '',
      usuario: '',
    }
  },

  traspaso: {
    fechahora: '',
    evento: '',
    detalles: {
      unidad_almacenaje_origen: '',
      nombre_unidad_almacenaje_origen: '',
      unidad_almacenaje_DESTINO: '',
      nombre_unidad_almacenaje: '',
      latitud: '',
      longitud: '',
      usuario: '',
    }
  },

  salida: {
    fechahora: '',
    evento: '',
    detalles: {
      unidad_almacenaje_origen: '',
      nombre_unidad_almacenaje_origen: '',
      latitud: '',
      longitud: '',
      usuario: '',
    }
  },

  atributos: {
    fechahora: '',
    evento: '',
    detalles: {
      atributo_anterior: '',
      valor_anterior: '',
      atributo_actual: '',
      valor_actual: '',
      latitud: '',
      longitud: '',
      usuario: '',
    }
  },

  documento_agregar: {
    fechahora: '',
    evento: '',
    detalles: {
      nombre: '',
      tamano: '',
      tipo: '',
      url: '',
      latitud: '',
      longitud: '',
      usuario: '',
    }
  },

  documento_eliminar: {
    fechahora: '',
    evento: '',
    detalles: {
      nombre: '',
      tamano: '',
      tipo: '',
      url: '',
      latitud: '',
      longitud: '',
      usuario: '',
    }
  },

  media_agregar: {
    fechahora: '',
    evento: '',
    detalles: {
      url: '',
      tipo: '',
      latitud: '',
      longitud: '',
      usuario_id: '',
    }
  },

  media_eliminar: {
    fechahora: '',
    evento: '',
    detalles: {
      url: '',
      tipo: '',
      latitud: '',
      longitud: '',
      usuario_id: '',
    }
  },

  codigos: {
    fechahora: '',
    evento: '',
    detalles: {
      codigo_anterior: '',
      codigo_actual: '',
      latitud: '',
      longitud: '',
      usuario: '',
    }
  },

  actualizar_ubicacion: {
    fechahora: '',
    evento: '',
    detalles: {
      latitud: '',
      longitud: '',
      usuario: '',
    }
  }

}